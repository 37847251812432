<script setup lang="ts">
import { Button, Space, type TablePaginationConfig } from 'ant-design-vue'
import type { RendererElement, RendererNode } from 'vue'
import { getDeviceModelsApi } from '@/api/device-model'
import { getSubSystemsApi } from '@/api/sub-system'
import { queryDevicesApi } from '@/api/device'
import { getDeviceGroupTreeApi } from '@/api/device-group'

const deviceRuntimeVisible = ref(false)
const selectedDevice = ref<Device>()

const search = ref<TableSearchType[]>([
  { label: '设备名称', name: 'deviceName', placeholder: '请输入设备名称', required: false, type: 'input' },
  { label: '设备编号', name: 'deviceCode', placeholder: '请输入设备编号', required: false, type: 'input' },
])

onMounted(initOptions)

async function initOptions() {
  const deviceModels = await getDeviceModelsApi()
  search.value.push({ label: '设备模型', name: 'deviceModelCode', placeholder: '请选择设备模型', required: false, type: 'select', options: deviceModels.map(({ deviceModelName, deviceModelCode }) => ({ label: deviceModelName, value: deviceModelCode })) })

  const subSys = await getSubSystemsApi()
  search.value.push({ label: '子系统', name: 'subSysId', placeholder: '请选择子系统', required: false, type: 'select', options: subSys.map(({ id, subSysName }) => ({ label: subSysName, value: id })) })

  const deviceGroupTree = await getDeviceGroupTreeApi()
  search.value.push({ label: '空间位置', name: 'deviceGroupIds', placeholder: '请选择', required: false, type: 'tree-select', options: deviceGroupTree })
}

const params = ref<DeviceQueryParam>({ current: 1, size: 20 })
const state = reactive<TableState>({
  data: [],
  columns: [
    { title: '设备名称', dataIndex: 'deviceName' },
    { title: '设备编号', dataIndex: 'deviceCode' },
    { title: '所属设备模型', dataIndex: 'deviceModelName' },
    { title: '所属子系统', dataIndex: 'subSysName' },
    { title: '空间位置', dataIndex: 'deviceGroupPaths', customRender: ({ record }) => {
      if (record.deviceGroupPaths == null)
        return ''
      // return 'record.deviceGroupPaths.split(\'--\')'
      return record.deviceGroupPaths.join(' / ')
    } },
    { title: '操作', width: 150, customRender: ({ record }) => {
      const renderArr: globalThis.VNode<RendererNode, RendererElement, { [key: string]: any }>[] = []

      renderArr.push(h(Button, { type: 'link',size:"small", onClick: () => showHistoryVideo(record) }, () => '实时状态'))

      return h(Space, { direction: 'horizontal' }, () => renderArr)
    } },
  ],
  config: {
    total: 0,
    loading: false,
    isBorder: false,
    isSerialNo: true,
    isSelection: true,
    isOperate: true,
  },
  pagination: {
    position: ['bottomLeft'],
    pageSize: 20,
    pageSizeOptions: ['20', '30', '40', '50'],
    total: 0,
    current: 1,
    showTotal: total => `共${total}条`,
  },
})

onMounted(refreshList)

async function refreshList() {
  state.config.loading = true
  const { records, current, size, total } = await queryDevicesApi(params.value)
  state.data = records
  const pagination = state.pagination as TablePaginationConfig
  pagination.current = current
  pagination.pageSize = size
  pagination.total = total

  setTimeout(() => {
    state.config.loading = false
  }, 500)
}

async function onSearch(form: any) {
  params.value = { ...form, current: 1, size: (state.pagination as TablePaginationConfig).pageSize ?? 20 }
  await refreshList()
}

async function onTablePageChange(current: number, pageSize: number) {
  (state.pagination as TablePaginationConfig).current = current;
  (state.pagination as TablePaginationConfig).pageSize = pageSize
  params.value.current = current
  params.value.size = pageSize

  await refreshList()
}

function showHistoryVideo(device: Device) {
  selectedDevice.value = device
  deviceRuntimeVisible.value = true
}

function onCloseDetail() {
  deviceRuntimeVisible.value = false
}
</script>

<template>
  <page-container>
    <content-header title="资产列表" />
    <flex-content>
      <div h-full overflow-hidden flex flex-col>
        <div style="background-color: var(--bg-color);" p-12px rounded-4px mb-8px>
          <search-form :search="search" @search="onSearch" />
        </div>

        <div flex-1>
          <flow-hidden-container>
            <table-layout :need-header="false">
              <template #content>
                <base-table v-bind="state" @page-change="onTablePageChange" />
              </template>
            </table-layout>
          </flow-hidden-container>
        </div>
      </div>
    </flex-content>

    <DeviceRuntime :visible="deviceRuntimeVisible" :device-code="selectedDevice?.deviceCode" :device-name="selectedDevice?.deviceName" @close="onCloseDetail" />
  </page-container>
</template>
